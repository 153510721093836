import useTypingEffect from '../../hooks/useTypingEffect';
import Row from 'react-bootstrap/esm/Row';
import Ratio from 'react-bootstrap/esm/Ratio';

const GoogleMap = () => {
	const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

	const animateTitle = useTypingEffect('מיקום המשרד');

	return (
		<>
			<h2 className="fs-2 text-light text-center">{animateTitle}</h2>
			<div className="p-3 bgTransperent-2 border border-primary rounded boxShadowWhiteBot-10">
				<Row>
					<Ratio aspectRatio="16x9">
						<iframe
							src={`https://www.google.com/maps/embed/v1/place?q=54%20%D7%A2%D7%9E%D7%A7%20%D7%A8%D7%A4%D7%90%D7%99%D7%9D%2C%20Jerusalem%2C%20Israel&key=${apiKey}`}
							title="Google map office location"
							allowFullScreen
							className="px-3"
						></iframe>
					</Ratio>
				</Row>
			</div>
		</>
	);
};

export default GoogleMap;
