import { useState } from 'react';
import useTypingEffect from '../../hooks/useTypingEffect';
import { FaEnvelope } from 'react-icons/fa';
import Modal from 'react-bootstrap/Modal';
import ContactMe from '../contactMe/ContactMe';

const Footer = () => {
	const paragraphText = useTypingEffect('רוצים גם אפליקציה כזאת?');
	const paragraphText2 = useTypingEffect('תשירו פרטים ונחזור אליכם בהקדם!');

	const [showModal, setShowModal] = useState(false);

	const handleModalClose = () => setShowModal(false);
	const handleModalOpen = () => setShowModal(true);

	return (
		<div className="my-4 text-center text-light p-3 bgTransperent-2 rounded">
			<p className="mb-1">{paragraphText}</p>
			<p className="mb-0">{paragraphText2}</p>
			<div className="fs-2 lh-1 mb-2">&darr;</div>
			<button
				onClick={handleModalOpen}
				aria-label="צור קשר"
				className="border-0 bg-transparent"
			>
				<FaEnvelope
					fill="#dc3545"
					size={30}
					title="לחץ כדי ליצור איתנו קשר עבור אפליקציה משלך"
				/>
			</button>
			<p className="my-4">&copy; 2024 WeBroker Group</p>
			<Modal
				show={showModal}
				onHide={handleModalClose}
				className="text-light animationZoomInOut"
			>
				<Modal.Header
					closeButton
					closeVariant="white"
					className="border-0 modalBgColor"
				></Modal.Header>
				<Modal.Body className="modalBgColor">
					<ContactMe handleModalClose={handleModalClose} />
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default Footer;
